<template>
  <div
    :class="[$style['order-short'], isActive && $style['active']]"
    @click="setActiveOrder"
  >
    <div :class="$style['order-short__main']">
      <div :class="$style['order-short__descr']">
        <div :class="$style['order-short__descr__title']">
          {{ order.game.title }}
        </div>
        <div :class="$style['order-short__descr__date']">
          {{ getHumanDateTime(order.created) }}
        </div>
      </div>
      <div :class="$style['order-short__sum-status']">
        <div :class="$style['order-short__sum']">
          {{ toLocalNumber(+order.total) }} ₽
        </div>
        <div :class="$style['order-short__status']">
          <Status :status="order.status"
            >{{ t(`topup.${order.status}`) }}
          </Status>
        </div>
      </div>
    </div>
    <div :class="$style['order-short__products']">
      <OrderShortProduct
        v-for="product in orderProducts"
        :key="product.id"
        :picture="product.picture"
        :class="$style['order-short__products__item']"
      />

      <div
        v-if="order.order_products.length > 3"
        :class="[
          $style['order-short__products__item'],
          $style['order-short__products__more-wrapper'],
        ]"
      >
        <div :class="$style['order-short__products__more']">
          {{ t("topup.more", { count: order.order_products.length - 2 }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Order } from "~/types/order.ts";
import Status from "~/components/Shared/Status/Status.vue";
import OrderShortProduct from "~/components/Profile/OrderHistory/OrderShortProduct.vue";
import { getHumanDateTime } from "~/utils/dates/human_date.ts";

const props = defineProps<{
  order: Order;
  isActive: boolean;
}>();

const { order, isActive } = toRefs(props);

const emit = defineEmits<{
  (e: "set-active-order", id: Order["id"]): void;
}>();

const { t } = useI18n();

const orderProducts = computed(() => {
  if (order.value.order_products?.length > 3) {
    return order.value.order_products.slice(0, 2);
  }

  return order.value.order_products?.slice(0, 3);
});

const setActiveOrder = () => {
  emit("set-active-order", order.value.id);
};
</script>

<style module lang="scss">
.order-short {
  width: 366px;
  padding: var(--spacing-xl);
  border-radius: 30px;
  background: var(--general-underlay-2-lvl);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  cursor: pointer;

  @media screen and (max-width: $tablet) {
    max-width: 360px;
    width: auto;
  }

  @media screen and (max-width: $mobile) {
    max-width: unset;
    width: 100%;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .order-short__descr {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--spacing-3xs);

      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.1px;
      }

      &__date {
        color: var(--general-transparent-light-50);
        font-size: 14px;
        font-weight: 500;
        line-height: 110%;
        letter-spacing: -0.07px;
      }
    }

    .order-short__sum-status {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: var(--spacing-xs);

      .order-short__sum {
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.08px;
      }
    }
  }

  &__products {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-2xs);
    align-self: stretch;

    &__item {
      width: 54px;
      height: 54px;
      border-radius: var(--radius-2xl);
      background: #282627;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &__more-wrapper {
      background: var(--general-transparent-dark-85);
    }

    &__more {
      font-size: 10px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.05px;
      width: min-content;
    }
  }
}

.active {
  background: var(--general-transparent-light-10);
}
</style>
