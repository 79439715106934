<template>
  <div class="swiper_wrapper">
    <NuxtErrorBoundary>
      <swiper-comp
        :slides-per-view="'auto'"
        :space-between="12"
        :speed="swiperSet.speed"
        :navigation="swiperSet.navigation"
        :modules="swiperSet.modules"
        :slide-to-clicked-slide="true"
        :loop="swiperSet.loop"
      >
        <template v-if="orders">
          <swiper-slide
            v-for="order in orders"
            :key="order.id"
          >
            <OrderShort
              :is-active="activeOrderId === order.id"
              :order="order"
              @set-active-order="setActiveOrder"
            />
          </swiper-slide>
        </template>
      </swiper-comp>
    </NuxtErrorBoundary>
  </div>
</template>

<script lang="ts" setup>
import { Swiper as SwiperComp, SwiperSlide } from "swiper/vue";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import OrderShort from "~/components/Profile/OrderHistory/OrderShort.vue";
import type { SwiperOptions } from "swiper/types";
import type { Order } from "~/types/order.ts";

const props = defineProps<{
  activeOrderId: number;
  orders: Order[];
}>();

const { activeOrderId, orders } = toRefs(props);

const emit = defineEmits<{
  (e: "set-active-order", id: Order["id"]): void;
}>();

const mySwiper = ref<Swiper | null>(null);

const swiperSet: SwiperOptions = reactive({
  speed: 600,
  navigation: {
    prevEl: ".swiper-button-prev",
    nextEl: ".swiper-button-next",
  },
  modules: [Navigation],
  loop: true,
});

onMounted(() => {
  mySwiper.value = new Swiper(".swiper");
});

watch(
  () => activeOrderId.value,
  (val: number) => {
    if (mySwiper.value) {
      mySwiper.value.activeIndex = val;
    }
  },
);

const setActiveOrder = (id: Order["id"]) => {
  emit("set-active-order", id);
};
</script>

<style lang="scss">
.swiper_wrapper {
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    transition: box-shadow 0.4s linear;

    // Блок слайда
    &-slide {
      width: 360px !important;
      height: 100%;
    }
  }
}
</style>
