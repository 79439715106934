<template>
  <div :class="$style['orders-history__list']">
    <div
      v-if="orders?.length && !loading"
      :class="$style['orders-history__list__wrapper']"
    >
      <div
        v-if="!hideShort"
        :class="$style['orders-history__short']"
      >
        <OrderShortTablet
          v-if="isTablet"
          :active-order-id="activeOrderId"
          :orders="orders"
          @set-active-order="setActiveOrder"
        />
        <div
          v-else-if="!isTablet && orders.length"
          :class="$style['orders-history__short-orders']"
        >
          <OrderShort
            v-for="order in orders"
            :key="order.id"
            :order="order"
            :is-active="activeOrderId === order.id"
            @set-active-order="setActiveOrder"
          />
        </div>
      </div>
      <OrderDescription
        v-if="!isMobile"
        :order="activeOrder"
      />
      <template v-else>
        <transition name="popup">
          <PopupContainer
            v-if="showOrderPopup && activeOrder"
            @hide="handleHide"
          >
            <OrderPopup
              v-if="showOrderPopup"
              :order="activeOrder"
              @hide="handleHide"
            ></OrderPopup>
          </PopupContainer>
        </transition>
      </template>
    </div>
    <p v-else>
      <LoadingSpinner v-if="loading" />
      <EmptyHistory v-else />
    </p>
  </div>
</template>

<script setup lang="ts">
import OrderPopup from "~/components/Profile/OrderHistory/OrderPopup.vue";
import OrderDescription from "~/components/Profile/OrderHistory/OrderDescription.vue";
import LoadingSpinner from "~/components/Shared/Spinners/LoadingSpinner.vue";
import PopupContainer from "~/components/Shared/Popup/PopupContainer.vue";
import EmptyHistory from "~/components/Profile/OrderHistory/EmptyHistory.vue";
import OrderShortTablet from "~/components/Profile/OrderHistory/OrderShortTablet.vue";
import OrderShort from "~/components/Profile/OrderHistory/OrderShort.vue";
import type { Order } from "~/types/order.ts";

const props = defineProps<{
  loading: boolean;
  activeOrderId: number;
  orders?: Order[];
  hideShort?: boolean;
}>();

const { loading, activeOrderId, orders, hideShort } = toRefs(props);

const emit = defineEmits<{
  (e: "set-active-order-id", id: number): void;
}>();

const { isTablet, isMobile } = useDevice();
const showOrderPopup = ref(false);

if (hideShort.value && orders.value && orders.value[0]) {
  setActiveOrder(orders.value[0].id);
}

function setActiveOrder(id: Order["id"]) {
  emit("set-active-order-id", id);
  if (isMobile.value) {
    showOrderPopup.value = true;
    document.documentElement.style.overflow = "hidden";
  }
}

const activeOrder = computed(() => {
  return orders.value?.find(
    ({ id }: { id: number }) => id === activeOrderId.value,
  ) as Order;
});

function handleHide() {
  showOrderPopup.value = false;
  document.documentElement.style.overflow = "";
}
</script>

<style module lang="scss">
.orders-history__list {
  &__wrapper {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-2lg);
    align-self: stretch;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }
}

.orders-history__short {
  @media screen and (max-width: $tablet) {
    width: 100%;
  }

  &-orders {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
  }
}
</style>
