<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M58.6666 32C58.6666 46.7276 46.7275 58.6667 31.9999 58.6667C17.2723 58.6667 5.33325 46.7276 5.33325 32C5.33325 17.2724 17.2723 5.33337 31.9999 5.33337C46.7275 5.33337 58.6666 17.2724 58.6666 32ZM27.0873 22.1755C27.0873 21.0128 26.1447 20.0702 24.982 20.0702C23.8193 20.0702 22.8768 21.0128 22.8768 22.1755V27.7895C22.8768 28.9522 23.8193 29.8948 24.982 29.8948C26.1447 29.8948 27.0873 28.9522 27.0873 27.7895V22.1755ZM39.0178 20.0702C40.1805 20.0702 41.1231 21.0128 41.1231 22.1755V27.7895C41.1231 28.9522 40.1805 29.8948 39.0178 29.8948C37.8551 29.8948 36.9126 28.9522 36.9126 27.7895V22.1755C36.9126 21.0128 37.8551 20.0702 39.0178 20.0702ZM20.4346 42.7432C19.7404 43.6678 19.9272 44.98 20.8518 45.6741C21.7763 46.3682 23.0885 46.1814 23.7827 45.2569C27.8349 39.8594 35.9947 39.8647 40.1047 45.2674C40.8047 46.1876 42.118 46.3661 43.0382 45.6661C43.9583 44.9661 44.1368 43.6528 43.4368 42.7326C37.6683 35.1498 26.1783 35.0927 20.4346 42.7432Z"
      fill="white"
      fill-opacity="0.1"
    />
  </svg>
</template>
