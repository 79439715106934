<template>
  <div
    :class="[
      $style['product-row'],
      (!product.codes || product.codes.length <= 1) && $style['one-code'],
    ]"
  >
    <div :class="$style['product-row-descr']">
      <div
        :class="[
          $style['product-row-descr_img'],
          !product.picture && $style['product-row-descr_img-default'],
        ]"
      />
      <div :class="$style['product-row-descr_wrapper']">
        <div :class="$style['product-row-descr_sum']">
          {{ product.title }}
        </div>
        <div :class="$style['product-row-descr_price']">
          <span v-if="!isMobile"
            >{{ `${product.quantity} ${quantityCase}` }}
          </span>
          <span v-if="!isMobile">•</span>
          <span> {{ product.quantity * product.price }} ₽ </span>
        </div>
      </div>
    </div>
    <div
      v-if="product.codes && product.codes.length"
      :class="$style['code-list']"
    >
      <TopupPromo
        v-for="code in product.codes"
        :key="code.code"
        :promo="code"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import TopupPromo from "~/components/Profile/TopupPromo.vue";
import type { OrderProduct } from "~/types/order.ts";
import { usePluralize } from "~/composables/usePluralize.ts";

const props = defineProps<{
  product: OrderProduct;
}>();

const { product } = toRefs(props);
const { t } = useI18n();
const { isMobile } = useDevice();
const productBg = computed(() => {
  return product.value.picture
    ? `url(${product.value.picture}) #4b4a4ab8 50% / cover no-repeat`
    : "";
});

const quantityCase = computed(() => {
  return usePluralize(product.value.quantity, [
    t("topup.products.one"),
    t("topup.products.two"),
    t("topup.products.three"),
  ]);
});
</script>

<style module lang="scss">
.product-row {
  display: flex;
  padding: var(--spacing-2xl);
  justify-content: space-between;
  align-items: flex-start;
  border-radius: var(--radius-5xl);
  background: var(--general-transparent-mid-5);

  @media screen and (max-width: 540px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--spacing-md);
  }

  @media screen and (max-width: $mobile) {
    min-height: 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
    padding: 0;
    background: none;
  }

  .code-list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    border-radius: var(--radius-3xl);

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }

  .code-list + .code-list {
    padding: 0.25rem 0;
  }

  &-descr {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);

    @media screen and (max-width: $mobile) {
      width: 100%;
    }

    &_img {
      width: 64px;
      aspect-ratio: 1;
      background: v-bind(productBg);
      border-radius: var(--radius-md);

      @media screen and (max-width: $mobile) {
        min-width: 48px;
      }
    }

    &_img-default {
      background: url("@/assets/product-img.webp") #4b4a4ab8 50%/ 90% no-repeat !important;

      @media screen and (max-width: $mobile) {
        background: url("@/assets/product-img.webp") #4b4a4ab8 50%/ 80% no-repeat !important;
      }
    }

    &_wrapper {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: $mobile) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }

      & > div {
        font-weight: 500;
      }

      .product-row-descr_sum {
        font-size: 18px;
        line-height: 135%;
        letter-spacing: -0.036px;

        @media screen and (max-width: $mobile) {
          max-width: 70%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        @media screen and (max-width: 340px) {
          max-width: 140px;
        }
      }

      .product-row-descr_price {
        display: flex;
        align-items: center;
        gap: 2px;
        color: var(--general-transparent-light-35);
        font-size: 0.75rem;
        line-height: 130%;

        @media screen and (max-width: $mobile) {
          font-size: 18px;
          line-height: 135%;
          letter-spacing: -0.036px;
        }
      }
    }
  }
}
</style>
