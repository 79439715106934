import type { Order } from "~/types/order.ts";

export async function getGameDataFromOrder(order: Order) {
  const productsStore = useProductsStore();
  try {
    await productsStore.init(`${order.game.id}`, false);
    if (!productsStore.gameData) {
      const notificationStore = useNotificationsStore();
      notificationStore.showNotification({
        text: productsStore.noProductsError,
        type: "error",
      });

      return false;
    }

    order.order_products.forEach((orderProduct) => {
      const productToAdd = productsStore.gameData?.products.find(
        (p) => p.data.id === orderProduct.id,
      );
      if (productToAdd) {
        productToAdd.amount = orderProduct.quantity;
      }
    });

    return true;
  } catch (e: any) {
    const notificationStore = useNotificationsStore();
    notificationStore.showNotification({
      text: e.message,
      type: "error",
    });
  }
}
