<template>
  <div :class="[$style.chip, $style[props.status]]">
    <p><slot /></p>
  </div>
</template>

<script lang="ts" setup>
import type { Status } from "~/types/status.ts";

const props = defineProps<{
  status: Status;
}>();
</script>

<style lang="scss" module>
.chip {
  padding: var(--spacing-xs) var(--spacing-sm);
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.07px;
  display: inline-flex;
  align-items: center;
  border-radius: var(--radius-max);
  background: var(--optional-transparent-mint);

  @media screen and (max-width: $mobile) {
    height: 24px;
    font-size: 12px;
    padding: 0 var(--spacing-xs);
  }
}

.done {
  background: rgba(68, 108, 86, 0.09);
  color: var(--optional-mint);
}

.failed {
  background: rgba(102, 66, 66, 0.38);
  color: #f96e6e;
}

.sent {
  background: rgba(255, 189, 141, 0.06);
  color: #fc8a37;
}

.paid {
  background: rgba(255, 189, 141, 0.06);
  color: #fc8a37;
}

.created {
  background: rgba(255, 189, 141, 0.06);
  color: #fc8a37;
}
</style>
