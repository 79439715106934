<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0359 1.82129C14.2782 1.82129 17.7665 5.0484 18.1774 9.18148C18.2309 9.71977 17.7883 10.1462 17.2681 10.1462C16.7396 10.1462 16.3326 9.71751 16.2698 9.21461C15.8833 6.11769 13.2396 3.72046 10.0359 3.72046C7.97716 3.72046 6.14975 4.70962 5.00439 6.239H6.01975C6.54325 6.239 6.96763 6.66414 6.96763 7.18859C6.96763 7.71303 6.54325 8.13817 6.01975 8.13817H2.8909C2.31819 8.13817 1.85392 7.67307 1.85392 7.09934V3.97751C1.85392 3.45207 2.27911 3.02612 2.80361 3.02612C3.32811 3.02612 3.7533 3.45207 3.7533 3.97751V4.7607C5.25378 2.96364 7.5117 1.82129 10.0359 1.82129ZM9.964 18.1784C5.72167 18.1784 2.23336 14.9513 1.82251 10.8182C1.769 10.2799 2.21159 9.85349 2.73174 9.85349C3.26026 9.85349 3.6673 10.2822 3.73007 10.7851C4.11658 13.882 6.76031 16.2793 9.964 16.2793C12.0227 16.2793 13.8501 15.2901 14.9955 13.7607H13.9801C13.4566 13.7607 13.0322 13.3356 13.0322 12.8111C13.0322 12.2867 13.4566 11.8615 13.9801 11.8615H17.109C17.6817 11.8615 18.1459 12.3266 18.1459 12.9004V16.0222C18.1459 16.5476 17.7208 16.9736 17.1963 16.9736C16.6718 16.9736 16.2466 16.5476 16.2466 16.0222V15.239C14.7461 17.0361 12.4882 18.1784 9.964 18.1784Z"
      fill="white"
    />
  </svg>
</template>
