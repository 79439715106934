<template>
  <div
    v-if="picture"
    :class="$style['order-short__picture']"
  ></div>
  <div
    v-else
    :class="$style['order-short__picture-default']"
  ></div>
</template>

<script setup lang="ts">
const props = defineProps<{
  picture: string | null;
}>();

const { picture } = toRefs(props);

const pictureBg = computed(() => {
  return picture.value
    ? `url(${picture.value}) #313030b8 50% / cover no-repeat`
    : "";
});
</script>

<style module lang="scss">
.order-short__picture {
  background: v-bind(pictureBg) !important;
}
.order-short__picture-default {
  background: url("@/assets/product-img.webp") #4b4a4ab8 50%/ 90% no-repeat !important;
}
</style>
