<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3327 10.0013C18.3327 14.6036 14.6017 18.3346 9.99935 18.3346C5.39697 18.3346 1.66602 14.6036 1.66602 10.0013C1.66602 5.39893 5.39697 1.66797 9.99935 1.66797C14.6017 1.66797 18.3327 5.39893 18.3327 10.0013ZM9.31093 14.1263C9.08077 14.1263 8.89427 13.9397 8.89427 13.7096V8.8233C8.89427 8.59314 9.08077 8.40664 9.31093 8.40664H10.6828C10.9129 8.40664 11.0995 8.59314 11.0995 8.8233V13.7096C11.0995 13.9397 10.9129 14.1263 10.6828 14.1263H9.31093ZM9.99685 7.35074C9.6986 7.35074 9.44285 7.25219 9.22985 7.0551C9.01677 6.85801 8.91018 6.62098 8.91018 6.34399C8.91018 6.067 9.01677 5.82996 9.22985 5.63287C9.44285 5.43579 9.6986 5.33724 9.99685 5.33724C10.2978 5.33724 10.5535 5.43579 10.7639 5.63287C10.9769 5.82996 11.0835 6.067 11.0835 6.34399C11.0835 6.62098 10.9769 6.85801 10.7639 7.0551C10.5535 7.25219 10.2978 7.35074 9.99685 7.35074Z"
    />
  </svg>
</template>
